import React from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  ListItemText
} from '@mui/material';

import Divider from "@mui/material/Divider";
import EpubPrepositionCoverImage from "@/images/epub/preposition/cover.png";
import Example14AnswerCoverImage from "@/images/epub/preposition/example-14-answer.png";
import Yes24Icon from "@/images/epub/common/yes24_icon.svg";
import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";
import PlayBooksIcon from "@/images/epub/common/play_books_icon.svg";
import {useNavigate} from "react-router-dom";
import EpubSelfAffirmationCoverImage from '@/images/epub/self-affirmation/cover.png';
import Example13Image from "@/images/epub/self-affirmation/example-13.png";

import HomeListeningTrainerPlayImage from "@/images/home/listening-trainer-play.png";
import HomeQuizPlayImage from "@/images/home/quiz-play.png";
import {getDeviceStored} from "@/util/common/device";

const HomeMainView = ({drawerOpen}: HomeMainViewProps) => {
  const navigate = useNavigate();
  const isVerticalImgAlign = window.innerWidth < 600;

  return (<>
    <div className="mt-1 text-right">
      <span className="pr-1 text-green-600">믿을 수 있는 학습 시스템을 지향합니다.</span>
    </div>

    <h2 className="mt-2 text-3xl font-bold leading-normal">컨텐츠 바로가기</h2>
    <Divider sx={{ marginBottom: 1 }} />
    <Grid container spacing={2} justifyContent="center">
      
      <Grid item xs={6} sm={6} md={3}>
        <Card>
          <CardActionArea sx={{ backgroundColor: '#f8efba', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                          onClick={() => {navigate(process.env.PUBLIC_URL + "/service/quiz?fileUrl=" + process.env.PUBLIC_URL + "/content/service/quiz/samples/korean-spelling.mpqz.json");}}>
            <CardContent>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                  맞춤법 퀴즈 22선
              </Typography>
              <Typography variant="body2" component="div" className={'text-gray-400 text-xs'}>
                퀴즈
              </Typography>
              <Typography variant="body2" color="text.secondary">
                1. 치킨이 나온게 언제인데 <u><b>금새</b></u> 다 먹었냐?<br/>
                2. 파전을 크게 부치려고 하는데 <u><b>넓직한</b></u> 프라이팬이 있을까?<br/>
                3. 배가 <u><b>몇일째</b></u> 아픈지 모르겠어요.<br/>
                4. <u><b>오랜만에</b></u> 등산을 해볼까?...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <Card>
          <CardActionArea sx={{ backgroundColor: '#cdf1c3', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                          onClick={() => {navigate(process.env.PUBLIC_URL + "/service/listening-trainer?contentDetail=7KO964qQ64OQIOyCrMcK6re46rKD7J20IOusuOygnOuhnOuLpC4KxRKNlOufrOyatCDsmrTrqoXsnZgg7ZmU7IK06rO8IOuPjO2MlOunpOulvCDqsqzrjozrgrTripQgyFGNlCDqs6Dqt4DtlZzqsIAuCuyVhOuLiOuptMQY7Ya1xFHrsJTri8VE7Z6Y7Jy866GcIOunieyVhCDsi7jsm4wg7J206riw6rOgIO2VqOq7mCDlAMjJZ%2ByYs%2BydgCDsnbzsnbjGZ8cgsbTkAN%2FsoIAg7J6g7J6QxzUuCsQP5AChk6TkAIzrp4jsnYzkAIzsg4Hsi6zrj4QsIOycoeyLxiasvOugpOuwm8Q%2F7IiY7LKcIOqwgOyngCDtg4Dqs6DrgpznANDrj4Qg64Gd64KYxCzrspXHa4Qg7J7lAOboALaAIOq%2FiMQY6r68xxjrp5DkAOmyoOyngC4g7JWELOsBnOyWtOugpOQBiuuFuOumh%2BydtOcBqSAK7Jqw66as6rDkAQO06QC4mCDsh6DsgqzsiqzEaOuBjOQBQeyjvegA64iY5AD87IaNxR%2BTpOyWtOqwiCDrlYwgxlLlAO%2BWtOuWpOoArbjqsowg65Cg5gDG5AFuPyAK5AF6sI3FfZWE66y05AEG6uUB1uQAleuPjOyVhOyYqOYBorQg7JeGxFvrr7jsp4DlAIuggOyKueyX5QGgx3LrrLTshJzsm4DFMp6I64qU5AFx66qo66XkAg7sl5As5QDw6AJJhCDssLjkAOPrmJDlAo6UlOwCJOQCeZDquYw%2FIArrp4zsnbzlAQuWxGXIbJq0IOQA3uQAn4vkAhgsIOuIhOkBV4S45AIdl5DHMeqxsMQx7OQBtekBXuQBQO4BGbQg7Iuc6rCE5QDngqzrgpjFNrGE5QExhOgAqamwLCDqtozroKXsnpDFLJ6Y66q75ANm7IS464%2FkAIfEF%2BupuOyLnCwg6rK9xAuggeyduMVYnpHEH%2BcBDIqk5AO2m4DEP%2BuBnecBcOyGjOyGoSwg6rSA5AHdk6TFaJik66eM5AJp5AJD5AIhnbjrgrTFTbCA7LmY5AEA7ZWY7LCu5QNknpDEOZeQ5QIH5gMS5gCU66W85gGQ5AGik%2BcCIIDmAc67xC7nAZ7sp4DrguQC5uuKlOQBJQrri6jqssRknMVeo6jlApahsOyaqe2VmMQz7Y647JWI7ZW0xDyKlOuNsC7oAZrxAYnsp%2BQDkSDtlLzqs6TFVJ247IOd7JeQIOUDFoztlZjrqbAg65WAxC%2FtnZjkAPjqAI7kAiimhOuL5QNr7Jik7cQQrOyW5AK2rMVCtOyXrOUDmuuM5QGT6riw64%2BE66Gc7I2oIOQFQLCcIOQEAOQFFLWs5AE7l6wgxFGYpC4%3D&voiceLang=ko-KR");}}>
            <CardContent>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                  연극 햄릿 유명 대사
              </Typography>
              <Typography variant="body2" component="div" className={'text-gray-400 text-xs'}>
                듣기 트레이너
              </Typography>
              <Typography variant="body2" color="text.secondary">
                죽느냐 사느냐 그것이 문제로다.
                이 더러운 운명의 화살과 돌팔매를 견뎌내는 것이 더 고귀한가.
                아니면 고통의 바다를 힘으로 막아...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <Card>
          <CardActionArea sx={{ backgroundColor: '#d2d4fb', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                          onClick={() => {navigate(process.env.PUBLIC_URL + "/service/listening-trainer?contentDetail=Rm91ciBzY29yZSBhbmQgc2V2ZW4geWVhcnMgYWdvIMQeZmF0aGVycyBicm91Z2h0IGZvcnRoIG9uIHRoaXMgY29udGluZW50IGEgbmV3IG5hdGlvbizEGGNlaXZlZCBpbiBsaWJlcnR5LMVkZGVkaWNhdGVkIHRvIHRoZSBwcm9wb3NpxDggdGhhdCBhbGwgbWVuIGFyZSBjcmXFLGVxdWFsLgpOb3cgd2XFGmVuZ2FnxmBhIGfEJyBjaXZpbCB3YXIsIHRlc3Rpbmcgd2hl5AC%2FxlnoAJ1vciBhbnnJD3Nv6wCv5QEHb%2BoApiwgY2FuIGxvbmcgZW5kdXJlLiBX5gCBbWV05AEHyH1iYXR0bGUtZmllbGQgb2bGcndhcsUwaGF2ZSBjb21l5AD1yFwgYSBwb3LlAPjIMMU%2BLCBhcyBhIGZpbmFsIHLnAMlwbGFjZeQBfSB0aG9zZSB3aG8gaGVyZSBnxFt0aGVpciBsaXZlc8ZJ6wDwIG1p5AG4xB0uIEl0IGlzIGFsdG9n5gEZZml0xWDkAPjkAYroASx3ZSBzaG91bGQgZOQBp2lzLgoKQnV0LOYBcGxhcmdlciBzZW5zZSzELOQBJW5vdOkA3M0VY29uc2VjctAXaGFsbG93xV0gZ3JvdW5kLiBUaGUgYnLkANNtZW4s5AC16ACdZGVhZCzlAPdzdHJ1Z2dsZWTlAQEs6AFdyGRkIGl0LCBmYXIgYWJvdmXlAsVwb2%2FEBXfkAN5vIGFkZOQCBGRldHJhY3TGdXdvcmxkIHdpbGwgbGnkAcrkAKNlLCBub3LmAf5yZW1lbWJlciB36AEfYXnnAIFidXQgaXTmANhlduQBVW9yZ2V0xip0aGV5IGRp5gCt6AGB5AHIdeQBq2XnAOMsIHLlA2gs5ACgYmXrAybEN%2BgDK3VuZmluaXNoZWTkALBrIHdoaWNoxmLkARlm5gOixTLlARV0aHVz5QEKc28gbm9ibHkgYWR2YW5jZWToAInGdukAkMV8xUDxA6fmAuZ0YXNr5AELYWlu5AGZYmVm5AQ8dXPigJTmAsFyb23EL3NlIGhvbm9yZeYBueQBMHRha2UgaW7kA9JzxRd2b%2BYD9MRg5AO%2BYXVz5gLi6wDX6ALeIGxhc3QgZnXlBBthc3VyZeQDNMhEyH535wC9aGlnaGx55AM8b2zFQOYBiHPkANdhZCBz5AJ65wKDdmUgZGnmBEd2YWnJSuUClegEIXVuZGVyIEdvZCzHO8U35gTxYmnlBQ9mIGZyZWVkb23igJTkAq7FRWdvdmVybm3kBR7lA%2BFlIHBlb3Bs5AIyec0P5gPXyhDqAKFwZXJpc2jpAVcgZWFydGgu&voiceLang=en-US");}}>
            <CardContent>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                링컨 유명 연설(영어)
              </Typography>
              <Typography variant="body2" component="div" className={'text-gray-400 text-xs'}>
                듣기 트레이너
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ...shall have a new birth of freedom—and that government of the people, by the people, for the people, shall not perish from the earth.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <Card>
          <CardActionArea sx={{ backgroundColor: '#f7c0fd', height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                          onClick={() => {navigate(process.env.PUBLIC_URL + "/service/quiz?fileUrl=" + process.env.PUBLIC_URL + "/content/service/quiz/samples/country-capital-quiz.json");}}>
            <CardContent>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                수도 퀴즈 26선
              </Typography>
              <Typography variant="body2" component="div" className={'text-gray-400 text-xs'}>
                퀴즈
              </Typography>
              <Typography variant="body2" color="text.secondary">
                1. 영국(🇬🇧)의 수도는?<br/>
                2. 프랑스(🇫🇷)의 수도는?<br/>
                3. 이탈리아(🇮🇹)의 수도는?<br/>
                4. 캐나다(🇨🇦)의 수도는?<br/>
                5. 독일(🇩🇪)의 수도는?<br/>
                6. 중국(🇨🇳)의 수도는?...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

    </Grid>

    <h2 className="mt-2 text-3xl font-bold leading-normal">서비스</h2>
    <List>
      <Divider />
      <ListItem key={'listening-trainer'} disablePadding>
        <ListItemButton onClick={() => {navigate("/service/listening-trainer");}}>
          <Box className="w-1/3">
            <img className="border-gray-500 border-2" src={HomeListeningTrainerPlayImage} alt={'HomeListeningTrainerPlayImage'}/>
          </Box>
          <ListItemText primary={<span className={'flex justify-center font-bold'}>듣기 트레이너</span>} secondary={
            <React.Fragment>
              <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="body2"
                  color="text.primary"
              >
                <p className={'mt-2 mb-2 flex justify-center text-center'}>
                  특정 문구를 외우기 위해 <br/>
                  혹은 명상을 위해
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  자주 반복 듣기가 필요해요.
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  듣기 트레이너로 반복 듣기를 해보세요.
                </p>
              </Typography>
            </React.Fragment>
          } />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItem key={'listening-trainer'} disablePadding>
        <ListItemButton onClick={() => {navigate("/service/quiz");}}>
          <Box className="w-1/3">
            <img className="border-gray-500 border-2" src={HomeQuizPlayImage} alt={'HomeQuizPlayImage'}/>
          </Box>
          <ListItemText primary={<span className={'flex justify-center font-bold'}>퀴즈</span>} secondary={
            <React.Fragment>
              <Typography
                sx={{display: 'inline'}}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <p className={'mt-2 mb-2 flex justify-center text-center'}>
                  한문제씩 답을 확인하며<br />
                  퀴즈를 풀어 볼 수 있는 서비스 입니다.
                </p>
              </Typography>
            </React.Fragment>
          } />
        </ListItemButton>
      </ListItem>
      <Divider />
    </List>

    { getDeviceStored()["app-version"] ? <></>: <>
      <h2 className="mt-4 text-3xl font-bold leading-normal">전자책</h2>
      <List>
        <Divider/>
        <ListItem key={'epub-preposition'} disablePadding>
          <ListItemButton onClick={() => {
            navigate("/epub/preposition");
          }}>
            {isVerticalImgAlign ? <Box sx={{padding: 0}} className="w-1/4">
              <img className="w-1/1" src={EpubPrepositionCoverImage} alt={'CoverImage'}/>
              <img className="border-2 w-1/1" src={Example14AnswerCoverImage} alt={'Example14AnswerCoverImage'}/>
            </Box> : <>
              <Box className="w-1/5">
                <img src={EpubPrepositionCoverImage} alt={'CoverImage'}/>
              </Box>
              <Box className="w-1/5">
                <img className="border-2" src={Example14AnswerCoverImage} alt={'Example14AnswerCoverImage'}/>
              </Box>
            </>}

            <ListItemText className={'pl-3'}
                          primary={<span className={'flex justify-center font-bold'}>미쁨 1문1답 영문 전치사</span>} secondary={
              <React.Fragment>
                <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <p className={'mt-2 mb-2 flex justify-center text-center'}>
                    전치사 표현에 자신감을!<br/>
                    회화할 때 가장 헷갈리는 전치사.<br/>
                    이젠 자신있게 말할 수 있어요.
                  </p>
                  <p className={'mb-2 flex justify-center text-center'}>
                    I saw her <span className={'underline text-gray-400'}>_(at/in/on)_</span> his car.
                  </p>
                  <p className={'mb-2 flex justify-center text-center'}>
                    밑줄에 들어갈 전치사가 헷갈린다면 확인해보세요!
                  </p>
                  <p className='flex justify-center text-center'>
                    <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
                    <Link href="https://www.yes24.com/Product/Goods/126670604"><span>예스24</span><OpenInNew
                      sx={{fontSize: 15}}/></Link>

                    <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
                    <Link
                      href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북</span><OpenInNew
                      sx={{fontSize: 15}}/></Link>
                  </p>
                </Typography>
              </React.Fragment>
            }/>
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem key={'epub-self-affirmation'} disablePadding>
          <ListItemButton onClick={() => {
            navigate("/epub/self-affirmation");
          }}>
            {isVerticalImgAlign ? <Box sx={{padding: 0}} className="w-1/4">
              <img className="w-1/1" src={EpubSelfAffirmationCoverImage} alt={'CoverImage'}/>
              <img className="border-2 w-1/1" src={Example13Image} alt={'Example14AnswerCoverImage'}/>
            </Box> : <>
              <Box className="w-1/5">
                <img src={EpubSelfAffirmationCoverImage} alt={'CoverImage'}/>
              </Box>
              <Box className="w-1/5">
                <img className="border-2" src={Example13Image} alt={'Example14AnswerCoverImage'}/>
              </Box>
            </>}
            <ListItemText className={'pl-3'}
                          primary={<span className={'flex justify-center font-bold'}>영어로 하는 미쁨 자기긍정확언</span>}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{display: 'inline'}}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <p className={'mt-2 flex justify-center text-center'}>
                                  I am cool.
                                </p>
                                <p className={'flex justify-center text-center text-gray-400'}>
                                  나는 멋져.
                                </p>
                                <p className={'flex justify-center text-center'}>
                                  I firmly believe in my self and my abilities.
                                </p>
                                <p className={'mb-2 flex justify-center text-center text-gray-400'}>
                                  나는 나 자신과 내 능력을 확고하게 믿는다.
                                </p>
                                <p className={'flex justify-center text-center'}>
                                  내 삶을 풍요롭게 하는 자기긍정확언.
                                </p>
                                <p className={'mb-2 flex justify-center text-center'}>
                                  영어 음성으로 자주 들어보세요!
                                </p>
                                <p className='flex justify-center text-center'>
                                  <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
                                  <Link href="https://www.yes24.com/Product/Goods/126670604"><span>예스24</span><OpenInNew
                                    sx={{fontSize: 15}}/></Link>

                                  <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
                                  <Link
                                    href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북</span><OpenInNew
                                    sx={{fontSize: 15}}/></Link>
                                </p>
                              </Typography>
                            </React.Fragment>
                          }/>
          </ListItemButton>
        </ListItem>
        <Divider/>
      </List>
    </>}
  </>);
};

interface HomeMainViewProps {
  drawerOpen: boolean;
}

export default HomeMainView;

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f7f7f7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
}));
